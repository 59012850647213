import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';

import colors from '../../../../config/theme/colors';

import { Box, Grid } from '@material-ui/core';
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { setRedux } from '../../../../js/utils/functions';
import * as moment from "moment";
import slugify from 'slugify';
import DialogModal from '../../../ui/dialog/DialogModal';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formShellCustom from './config/formShellCustom.config';

import { eventService } from '../../../../js/services/event.service';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { GET_ATTRIBUTES_BY_TYPE, UNIQUE_EAV_CODE } from '../../../../queries/attributes';
import { GET_SHELL_BY_EAVTYPE, CREATE_SHELL, UPDATE_SHELL, DELETE_SHELL, CREATE_SHELL_DATA, DELETE_SHELL_DATA, GET_DATAS_OF_A_SHELL, UPDATE_SHELL_DATA } from '../../../../queries/shells';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { listSettings, listMappers, perPageOptions } from './config/listShells.config';
import Listing from '../../../layouts/Listing/Listing';
import MasterSearch from '../../../ui/search/MasterSearch';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import OurTypography from '../../../ui/typography/Typography';
import styled from 'styled-components';


const PageWrapper = styled(Box)`
    width: 100%;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;

    & > .layout-wrapper{
        display: none;
    }
`;

class ListShellsCustom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            shells: [],
            isEmpty: false,
            loading: true,
            content: {
                emptyTitle: this.props.t("test.manage_test.youDoNotHave", { alias: this.props.match.params.alias }),
                emptySubtitle: this.props.t("test.manage_test.clickBelowToStart"),
                emptyTxtBtn: this.props.t("catalogs.catalogs.create"),
                emptyPicto: Empty,
            },
            editForm: false,
            errors: {},
            seeErrors: false,
            openForm: false,
            openDialog: false,
            customAttributes: [],
            sku: null,
            currentShell: null,
            currentShellCode:null,
            customSearchAttributesSearched: [],
            customSearchAttributes: [],
        }
        this.typingTimer = null;
    }

    getShells = () => {
        this.props.client.query({
            query: GET_SHELL_BY_EAVTYPE,
            fetchPolicy: 'no-cache',
            variables: { "eavType": `/api/eav-types/${this.props.match.params.id}`, page: 1, itemsPerPage: 50 }
        }).then(result => {
            if (result.data.shells.collection.length === 0) {
                this.setState({
                    isEmpty: true,
                });
            }
            else {
                this.setState({
                    shells: result.data.shells.collection
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    // CUSTOM SHELLS

    deleteShell = () => {
        this.props.startLoading()
        this.props.client.query({
            query: DELETE_SHELL,
            fetchPolicy: 'no-cache',
            variables: {
                "id": this.state.currentShell
            }
        }).then(result => {
            this.props.stopLoading();
            if (result.data.deleteShell) {
                this.handleToggleDrawer()
                this.props.snack(ALERT_SUCCESS, this.props.t("test.manage_test.shellRemoved"));
                this.reloadShells()
            }
        });
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    setValue = (stateName, value, translated) => {
        // if(stateName === 'product_price' && value.includes(',')){
        //     value = value.replace(',', '.');
        // }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if (stateName === "sku")
            this.checkIdentifier(stateName);
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    doneTyping = (stateName) => {
        if (stateName === 'shellName') {
            this.setState({
                attributeIdentifier: slugify(this.state.shellName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            })
        }

        if (this.state.shellName) {
            this.props.client.query({
                query: UNIQUE_EAV_CODE,
                fetchPolicy: 'no-cache',
                variables: {
                    "code": this.state[stateName]
                }
            }).then(result => {
                if (result.data.eavTypes.edges.length !== 0) {
                    eventService.fire({ stateName: 'shellName', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    let errors = this.state.errors;
                    errors[stateName] = true;
                    this.setState({
                        errors
                    })
                }
            });
        }
        this.forceUpdate();
    };

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups;
            let customAttributes = group.filter(e => !e.node.isSystem);
            let imageAttributes = customAttributes.filter(e => e.node.attributeType.input === 'image');

            this.setState({
                customAttributes,
                imageAttributes,
            });

            resolve();
        });
    }

    prepareAttributeValues() {
        return new Promise((resolve, reject) => {
            for (let locale of this.props.locales) {
                let values = {};

                for (let attribute of this.state.attributes) {
                    let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                    let defaultLocale = attribute.locales.find(e => e.code === this.state.currentLang);

                    if (!attributeLocale) {
                        if (attribute.attributeType.input === 'select') {
                            values[attribute.identifier] = defaultLocale?.value ?? attribute.locales[0];
                        }
                    } else {
                        switch (attribute.attributeType.input) {
                            case 'file':
                            case 'image':
                                values[attribute.identifier] = {
                                    // data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    filePath: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    file: null,
                                    changed: false,
                                    type: attributeLocale.media?.type,
                                    name: attributeLocale.media?.name,
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;
                        }
                    }
                }

                this.setState({
                    [locale.node.code]: values,
                });
            }
            resolve()
        })
    }

    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.data.shell.shellDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale?.id,
                            code: node.locale?.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }

            this.setState({ attributes }, resolve);
        });
    }

    addShell = async () => {
        this.setState({
            editForm: 'add',
        })
        await this.prepareAttributeValues();
        this.handleToggleDrawer();
    }

    editShell = async (id) => {
        this.props.client.query({
            query: GET_DATAS_OF_A_SHELL,
            fetchPolicy: 'no-cache',
            variables: { "id": id }
        }).then(async result => {
            if (result.data) {
                this.prepareAttributesLocales(result);
                await this.prepareAttributeValues();
                this.setState({
                    editForm: 'edit',
                    sku: result.data.shell.sku,
                    currentShell: id,
                })
                this.handleToggleDrawer();
            }
        })
    }

    initShell = () => {
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                variables: { "id": `/api/eav-types/${this.props.match.params.id}` },
                fetchPolicy: "no-cache",
            }).then(GET_ATTRIBUTES_BY_TYPE_RESULT => {
                const allGroups = GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes?.edges.filter(e => (!e.node.isSystem && !e.node.isForContent) || e.node.identifier === 'default');
                const sortedGroups = allGroups.sort((a, b) => a.node.position - b.node.position);
                this.setState({
                    sku: null,
                    allGroups: sortedGroups,
                    attributes: [],
                    currentShellCode: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.code,
                    name: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.name || GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.code
                    // status: true
                }, async () => {
                    await this.prepareAttributes();
                });
            });
            resolve();
        })
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = shell => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.customAttributes;

            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {

                        /*if (!formValue.changed)
                            continue;*/
                        isMedia = true;
                        /*formValue   = formValue.file;*/
                    }
                    if (formValue) {
                        // let resultMedia = null;

                        /* if (isMedia) { 
                             let formData = new FormData();
     
                             formData.append('file', formValue);
                             formData.append('type', formValue.type);
         
                             resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                         }*/

                        if (currentTranslation) {
                            // UPDATE STEP

                            let variables = {
                                "id": currentTranslation.productDataId,
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }

                            await this.props.client.mutate({
                                mutation: UPDATE_SHELL_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let ADD_SHELL_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }
                            ADD_SHELL_DATA_RESULT = await this.props.client.mutate({
                                mutation: CREATE_SHELL_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_SHELL_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let variables = {
                'id': this.state.currentShell,
                'sku': this.state.sku,
                'eavType': `/api/eav-types/${this.props.match.params.id}`,
                'createdAt': moment().format('YYYY-MM-DD'),
                'updatedAt': moment().format('YYYY-MM-DD'),
                'status': true,
            };
            if (this.state.editForm === "add") {
                const SHELL_RESULT_CREATE_RESULT = await this.props.client.mutate({
                    mutation: CREATE_SHELL,
                    variables
                });
                await this.saveAttributes(SHELL_RESULT_CREATE_RESULT.data.createShell.shell.id);
            } else {
                await this.props.client.mutate({
                    mutation: UPDATE_SHELL,
                    variables
                });
                await this.saveAttributes(this.state.currentShell);
                this.props.stopLoading();
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        if (this.state.editForm === "add") {
            this.props.stopLoading();
            this.handleToggleDrawer();
            this.props.snack(ALERT_SUCCESS, this.props.t("test.manage_test.shellAdded"));
            this.reloadShells()
        }
        else {
            this.props.stopLoading();
            this.handleToggleDrawer();
            this.props.snack(ALERT_SUCCESS, this.props.t("test.manage_test.shellModified"));
            this.reloadShells()
        }
        // await setRedux(false,true,false,false);
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        });
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    };

    handleClose = async () => {
        this.setState({
            errors: {},
            seeErrors: false,
        })
        await this.initShell();
        this.handleToggleDrawer()
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    //COMPONENT CYCLE

    componentDidMount = () => {
        checkRouting(this.props);
        this.initShell();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.initShell();
            this.setState({
                loading: true,
                isEmpty: false,
            });
        }
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        // this.forceUpdate();
    };

    getShellsCallback = () => {
        this.setState({
            reloadShells: false
        })
    }
    reloadShells = () => {
        this.setState({ reloadShells: true })
    }

    stateCallback = (stateName, value, custom, translated, callback) => {
        let customSearch = this.state.customSearchAttributes;
        if (!customSearch) {
            customSearch = {};
        }
        customSearch[stateName] = value?.target?.value ?? value;
        this.setState({
            customSearchAttributes: customSearch
        });
    };
    resetFilters = () => {
        this.setState({
            customSearchAttributesSearched: [],
            customSearchAttributes: []
        })
    };
    validateFilters = () => {
        this.setState({
            customSearchAttributesSearched: this.state.customSearchAttributes
        })
    };

    render() {
        let shellDatasFilters = [];
        if (this.state.customSearchAttributesSearched) {
            for (const [key, value] of Object.entries(this.state.customSearchAttributesSearched)) {
                if (value !== '') {
                    let option = null
                    let newValue = null
                    if (key !== 'product_image') {
                        option = value?.includes('/api/attribute-options')
                    } else {
                        if (value === "active") {
                            newValue = true
                        } else if (value === "inactive") {
                            newValue = false
                        }
                    }
                    if (value !== 'all') {
                        shellDatasFilters.push(JSON.stringify({
                            'identifier': key,
                            'value': option ? null : newValue !== null ? newValue : value,
                            'locale': option ? null : this.props.currentLang,
                            'option': option ? value.replace('/api/attribute-options/', '') : null
                        }))
                    }
                }
            }
        }
        return (
            <PageWrapper>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={ this.state.name?this.props.t("test.manage_test.manageDynamic", { alias: this.state.name }):null}
                    subtitle={this.state?this.props.t("test.manage_test.manageShellDynamic", { alias: this.state.name }):null}
                    handlerAdd={() => this.addShell()}
                    buttonAvailable={true}
                    textAdd={'+ ' + this.props.t("test.manage_test.add")}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Grid container>
                    {/* <Grid container justifyContent="center" alignItems="center" style={{marginBottom: 16}}>
                        <AccordionCustom defaultExpanded={false} title={<OurTypography variant="h5" colortext={colors.black.regular} style={{ fontSize: '14px' }}>Filtres et recherche</OurTypography>}>
                            <MasterSearch
                                currentStatus={this.state.searchStatus}  
                                searchStatusHandler={this.handleSearchStatus}
                                handleNameDesc={this.handleChange}
                                handleSearchSku={this.handleSearchSku}
                                validateSearch={this.validateFilters}
                                resetFilters={this.resetFilters}
                                categories={[]}
                                attributesInternalFilter={this.props.attributes[this.props.match.params.alias].attributes.edges.filter(e => e.node.internalFilter)}
                                stateCallback={this.stateCallback}
                                currentLang={this.props.currentLang}
                                allState={this.state}
                                noCategories={true}
                                isShell={true}
                            />
                        </AccordionCustom>
                    </Grid> */}
                    {/* <Listing
                        label='shell'
                        settings={listSettings(this.state)}
                        cardProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            textButton: 'Modifier',
                            handlerButton: this.editShell,
                            windowWidth: this.props.windowWidth,
                        }}
                        tableProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            textButton: 'Modifier',
                            handlerButton: this.editShell,
                            windowWidth: this.props.windowWidth,
                        }}
                        queryVariables={{
                            eavType: `/api/eav-types/${this.props.match.params.id}`,
                        }}
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        locales={this.props.locales}
                        identifier='shells'
                        viewsOptions={{
                            current: 'table',
                            settings: ['card', 'table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        typeResult="collection"
                        reload={this.state.reloadShells}
                        listingCallback={this.getShellsCallback}
                    /> */}
                    <Listing
                        label='shell'
                        settings={listSettings(this.state)}
                        pagination={true}
                        cardProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            textButton: 'Modifier',
                            handlerEdit: this.editShell,
                            windowWidth: this.props.windowWidth,
                            menuHandler: {
                                toggleDeleteDialog: this.handleToggleDialog
                            }
                        }}
                        tableProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            windowWidth: this.props.windowWidth,
                            typeButtonSecondary: 'edit',
                            textButtonSecondary: 'Modifier',
                            handlerButtonSecondary: this.editShell,
                        }}
                        queryVariables={{
                            eavTypeCode: this.props.match.params.alias,
                            shellDatasFilters: shellDatasFilters
                        }}
                        perPageOptions={perPageOptions}
                        handleNoResults={this.addShell}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        locales={this.props.locales}
                        identifier='customSearchableShells'
                        viewsOptions={{
                            current: 'table',
                            settings: ['card', 'table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        // typeResult="collection"
                        reload={this.state.reloadShells}
                        listingCallback={this.getShellsCallback}
                    />
                </Grid>

                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    forClose={this.handleClose}
                    handlerMutation={() => (this.handlerMutation())}
                    dataLayout={formShellCustom(this.state.editForm, this.state.currentLang, this.state.customAttributes, this.state.imageAttributes, this.state.errors, this.state.seeErrors, this.handleMediaPicker, this.props.t,this.state)}
                    allState={this.state}
                    icomoon={(this.state.editForm === 'edit') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    checkError={() => { }}
                    deleteMutation={
                        (this.state.editForm === 'edit') ?
                            this.handleToggleDialog
                            : null
                    }
                    deleteText={this.state.editForm === 'edit' ? this.props.t("test.manage_test.deleteShell") : null}
                    deleteButton={this.state.editForm}
                    validateButton={true}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    title={this.props.t("test.manage_test.sureRemoveShell")}
                    secondaryAction={this.handleToggleDialog}
                    primaryAction={() => { this.deleteShell(); this.handleToggleDialog() }}
                    windowWidth={this.props.windowWidth}
                >
                    {this.props.t("test.manage_test.verySureRemoveShell")}
                </DialogModal>
            </PageWrapper>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname: route,
            state: { id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListShellsCustom))));