import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { toggleExpandedForAll } from 'react-sortable-tree';
import { withApollo } from 'react-apollo';
import axios from 'axios';


import {
    GET_ATTR_GROUPE,
    GET_ATTRIBUTE_TYPES,
    GET_ATTR_GROUPE_WITH_ATTRIBUTES,
    UPDATE_ATTRIBUTE_STATUS,
    UPDATE_ATTRIBUTE_POSITION,
    ADD_ATTRIBUTE_GROUPE,
    DELETE_ATTRIBUTE_GROUPE,
    UPDATE_ATTRIBUTE,
    ADD_ATTRIBUTE,
    ADD_ATTRIBUTE_OPTION,
    DELETE_ATTRIBUTE_OPTION,
    GET_ATTRIBUTES_BY_TYPE,
    GET_ATTRIBUTE,
    ADD_ATTRIBUTE_DICTIONNARY,
    DELETE_ATTRIBUTE_DICTIONNARY,
    UPDATE_ATTRIBUTE_DICTIONNARY,
    ADD_ATTRIBUTE_OPTION_DICTIONNARY,
    UPDATE_ATTRIBUTE_OPTION_DICTIONNARY,
    DELETE_ATTRIBUTE_OPTION_DICTIONNARY,
    GET_EAV_TYPES
} from '../../../../queries/attributes';
import {
    ADD_TRANSLATION,
    ADD_TRANSLATION_DATA,
    UPDATE_TRANSLATION_DATA,
    DELETE_TRANSLATION_DATA
} from '../../../../queries/translations';

import formGroupAttributAdd from './config/formGroupAttributAdd.config';
import formGroupAttributEdit from './config/formGroupAttributEdit.config';
import formAttributEdit from './config/formAttributEdit.config';
import formAttributAdd from './config/formAttributAdd.config';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';
import { eventService } from '../../../../js/services/event.service';

import colors from '../../../../config/theme/colors';
import { SNACK, START_LOADING, STOP_LOADING, SET_ATTRIBUTES, SET_ATTRIBUTE_GROUPS } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getParams } from '../../../../js/utils/getParams';
import { setRedux } from '../../../../js/utils/functions';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import { GET_MEDIA_CATEGORIES } from '../../../../queries/mediaCategories';

class AttributesProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            openDialog: false,
            editForm: false,
            eavGrpType: '',
            attr_id: '',
            grp_id: '',
            parent: '',
            libelle: '',
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeType: '',
            attributUnity: 'none',
            categoriesData: [],
            listGroupAttr: [],
            mediaCategory:[],
            treeData: null,
            inputAttributes: [],
            currentLang: props.locales[0].node.code,
            groupIdentifier: null,
            errors: {},
            status: true,
            internalFilter: true,
            useInCard: false,
        };
        this.errors = {}
        this.typingTimer = null;
        this.typeTesting = 'attribute';
    }

    // [BEGIN] MUTATIONS
    // -- GROUPS
    saveAddGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let identifier = `${this.state.groupIdentifier}`;

                const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_TRANSLATION,
                    variables: { 'translationKey': `spread.attributeGroup.${identifier}` }
                });

                for (let locale of this.props.locales) {
                    if (this.state[locale.node.code]['groupName']) {
                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': this.state[locale.node.code]['groupName'],
                                'locale': locale.node.id,
                                'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                            }
                        });
                    }
                }

                let RESULT_ADD_ATTRIBUTE_GROUPE = await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE_GROUPE,
                    variables: {
                        'identifier': identifier,
                        'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
                        'isSystem': false,
                        'isForContent': false
                    }
                });
                let getAllGroups = this.props.attributeGroups;
                let newGroup = {
                    node: RESULT_ADD_ATTRIBUTE_GROUPE.data.createAttributeGroup.attributeGroup
                }
                getAllGroups.push(newGroup)
                this.props.setAttributeGroups(getAllGroups);
                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    saveEditGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code]['groupName'];
                    let currentTranslation = this.state.currentGroup.locales.find(e => e.id === locale.node.id);
                    if (formValue) {
                        if (currentTranslation) {
                            // UPDATE STEP
                            await this.props.client.mutate({
                                mutation: UPDATE_TRANSLATION_DATA,
                                variables: {
                                    'id': currentTranslation.translationDataId,
                                    'value': formValue
                                }
                            });
                        } else {
                            // CREATE STEP
                            await this.props.client.mutate({
                                mutation: ADD_TRANSLATION_DATA,
                                variables: {
                                    'value': formValue,
                                    'locale': locale.node.id,
                                    'translation': this.state.currentGroup.node.translation.id
                                }
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await this.props.client.mutate({
                            mutation: DELETE_TRANSLATION_DATA,
                            variables: { 'id': currentTranslation.translationDataId }
                        });
                    }
                }
                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    // -- OPTIONS
    deleteOption = (id) => {
        return new Promise(async (resolve, reject) => {
            const parts = id.split('/');
            const realId = parts[parts.length - 1];

            var data = await request(`${process.env.REACT_APP_API}/attribute-option/can-delete/${realId}`)

            if (data.isDeletable) {
                await this.props.client.mutate({
                    mutation: DELETE_ATTRIBUTE_OPTION,
                    variables: { 'id': id }
                });

                resolve();
            }

            this.props.snack(ALERT_ERROR, 'Impossible de supprimer cette valeur: '.concat(data?.message ? data.message : 'Impossible de supprimer cette valeur d\'attribut'))

            resolve();
        });
    };

    createOption = (attribute, option) => {
        return new Promise(async (resolve, reject) => {
            const identifier = `${option.code}`;

            const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                mutation: ADD_TRANSLATION,
                variables: { 'translationKey': `spread.attributeOption.${attribute.identifier}.${identifier}` }
            });

            await this.props.client.mutate({
                mutation: ADD_ATTRIBUTE_OPTION,
                variables: {
                    'identifier': identifier,
                    'attribute': attribute.id,
                    'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                }
            });

            for (let locale of this.props.locales) {
                let value = option.values[locale.node.code];

                if (value) {
                    await this.props.client.mutate({
                        mutation: ADD_TRANSLATION_DATA,
                        variables: {
                            'value': value,
                            'locale': locale.node.id,
                            'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                        }
                    });
                }
            }

            resolve();
        });
    };

    updateOption = (option) => {
        return new Promise(async (resolve, reject) => {
            for (let locale of this.props.locales) {
                let formValue = option.values[locale.node.code];
                let currentTranslation = this.state.currentAttribute.attributeOptions.edges.find(e => e.node.id === option.id);
                let currentTranslationData = currentTranslation.node.translation.translationDatas.edges.find(e => e.node.locale.id === locale.node.id);
                if (formValue) {
                    if (currentTranslation) {
                        // UPDATE STEP

                        await this.props.client.mutate({
                            mutation: UPDATE_TRANSLATION_DATA,
                            variables: {
                                'id': currentTranslationData.node.id,
                                'value': formValue
                            }
                        });
                    } else {
                        // CREATE STEP

                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': formValue,
                                'locale': locale.node.id,
                                'translation': this.state.currentGroup.node.translation.id
                            }
                        });
                    }
                } else if (currentTranslation &&currentTranslationData) {
                    // DELETE STEP

                    await this.props.client.mutate({
                        mutation: DELETE_TRANSLATION_DATA,
                        variables: { 'id': currentTranslationData.node.id }
                    });
                }
            }

            resolve();
        });
    };

    saveOptions = (attribute) => {
        return new Promise(async (resolve, reject) => {
            for (let option of this.state.values) {
                if (option.deleted)
                    await this.deleteOption(option.id);
                else if (option.new)
                    await this.createOption(attribute, option);
                else if (option.changed)
                    await this.updateOption(option);
            }

            resolve();
        });
    };

    // -- OPTIONS DICTIONNARY
    deleteEntry = (id, isSelect) => {
        let mutationName = null
        if (isSelect) {
            mutationName = DELETE_ATTRIBUTE_OPTION_DICTIONNARY
        } else {
            mutationName = DELETE_ATTRIBUTE_DICTIONNARY
        }
        return new Promise(async (resolve, reject) => {
            await this.props.client.mutate({
                mutation: mutationName,
                variables: { 'id': id }
            });

            resolve();
        });
    };

    createEntry = (attribute, entry, isSelect) => {
        return new Promise(async (resolve, reject) => {
            let input = entry.code
            /* if (entry.checkbox){
                input = `|%|${input}|%|`
            } */
            if (isSelect) {
                await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE_OPTION_DICTIONNARY,
                    variables: {
                        'input': input[0],
                        'attributeOption': entry.values,
                        'attribute': attribute.id
                    }
                });
            } else {
                await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE_DICTIONNARY,
                    variables: {
                        'input': input,
                        'output': entry.values,
                        'attribute': attribute.id
                    }
                });
            }
            resolve();
        });
    };

    updateEntry = (attribute, entry, isSelect) => {
        let input = entry.code
        return new Promise(async (resolve, reject) => {
            if (isSelect) {
                await this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE_OPTION_DICTIONNARY,
                    variables: {
                        'id': entry.id,
                        'attributeOption': entry.values,
                        "input": input[0],
                        'attribute': attribute.id
                    }
                });
            } else {
                await this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE_DICTIONNARY,
                    variables: {
                        'id': entry.id,
                        'output': entry.values,
                        "input": input,
                        'attribute': attribute.id
                    }
                });
            }
            resolve();
        });
    };

    saveDictionnary = (attribute, isSelect) => {
        return new Promise(async (resolve, reject) => {
            for (let entry of this.state.dictionnaryValues) {
                if (entry.deleted)
                    await this.deleteEntry(entry.id, isSelect);
                else if (entry.new && entry.code && entry.values)
                    await this.createEntry(attribute, entry, isSelect);
                else if (entry.changed && entry.code && entry.values)
                    await this.updateEntry(attribute, entry, isSelect);
            }

            resolve();
        });
    };

    // -- ATTRIBUTES
    saveAddAttr = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let identifier = `attribute_${this.state.attributeIdentifier}`;
                const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_TRANSLATION,
                    variables: { 'translationKey': `spread.attribute.${identifier}` }
                });

                for (let locale of this.props.locales) {
                    if (this.state[locale.node.code]['attributeName']) {
                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                value: this.state[locale.node.code]['attributeName'],
                                locale: locale.node.id,
                                translation: ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                            }
                        });
                    }
                }

                const ADD_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE,
                    variables: {
                        identifier: this.state.attributeIdentifier,
                        attributeGroup: this.state.selectedGroups.map(e => e.node.id),
                        eavType: this.props.attributes.eavTypes.find(e => e.node.code === this.props.typeAttributs).node.id,
                        attributeType: this.state.attributeType,
                        isRequired: this.state.isRequired,
                        isSearchable: this.state.isSearchable,
                        useInCard: this.state.useInCard,
                        isSystem: false,
                        status: true,
                        translation: ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
                        internalFilter: this.state.internalFilter || false,
                    }
                });

                if (this.state.attributeType === this.state.choiceType.node.id)
                    await this.saveOptions(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute);

                // ADD DICTIONNARY
                if (this.state.attributeType === this.state.choiceType.node.id) {
                    await this.saveDictionnary(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute, true)
                } else {
                    await this.saveDictionnary(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute);
                }
                // ADD ATTRIBUTE TO STORE AND LOCAL STORAGE

                const GET_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: GET_ATTRIBUTE,
                    variables: {
                        'id': ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id
                    }
                });

                this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE_POSITION,
                    variables: {
                        'id': ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id,
                        'position': parseInt(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id.replace("/api/attributes/", '')),
                    }
                })
                let getAllGroups = this.props.attributeGroups;

                for (let group of this.state.selectedGroups) {
                    let getGroup = getAllGroups.find(e => e.node.id === group.node.id);
                    if (getGroup) {
                        getGroup.node.attributes.edges.push({ node: GET_ATTRIBUT_RESULT.data.attribute });
                    }
                }
                this.props.attributes.product.attributes.edges.push({ node: GET_ATTRIBUT_RESULT.data.attribute });
                localStorage.setItem('ATTRIBUTES', JSON.stringify(this.props.attributes));
                localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(getAllGroups));
                this.props.setAttributes(this.props.attributes);
                this.props.setAttributeGroups(getAllGroups);

                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    saveEditAttr = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const UPDATE_ATTRIBUTE_RESULT = await this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE,
                    variables: {
                        id: this.state.currentAttribute.id,
                        attributeGroup: this.state.selectedGroups.map(e => e.node.id),
                        attributeType: this.state.attributeType,
                        isRequired: this.state.isRequired,
                        isSearchable: this.state.isSearchable,
                        internalFilter: this.state.internalFilter || false,
                        useInCard: this.state.useInCard || false,
                    }
                });

                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code]['attributeName'];
                    let currentTranslation = this.state.currentAttribute.translation.translationDatas.edges.find(e => e.node.locale.id === locale.node.id);
                    if (formValue) {
                        if (currentTranslation) {
                            // UPDATE STEP

                            await this.props.client.mutate({
                                mutation: UPDATE_TRANSLATION_DATA,
                                variables: {
                                    'id': currentTranslation.node.id,
                                    'value': formValue
                                }
                            });
                        } else {
                            // CREATE STEP

                            await this.props.client.mutate({
                                mutation: ADD_TRANSLATION_DATA,
                                variables: {
                                    'value': formValue,
                                    'locale': locale.node.id,
                                    'translation': this.state.currentAttribute.translation.id
                                }
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_TRANSLATION_DATA,
                            variables: { 'id': currentTranslation.node.id }
                        });
                    }
                }

                if (this.state.attributeType === this.state.choiceType.node.id)
                    await this.saveOptions(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute);


                // UPDATE DICTIONNARY    
                if (this.state.attributeType === this.state.choiceType.node.id) {
                    await this.saveDictionnary(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute, true)
                } else {
                    await this.saveDictionnary(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute);
                }

                // UPDATE ATTRIBUTE IN STORE AND LOCAL STORAGE

                const GET_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: GET_ATTRIBUTE,
                    variables: {
                        'id': UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id
                    }
                });

                for (let attribute of this.props.attributes.product.attributes.edges) {
                    if (attribute.node.id === UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id) {
                        attribute.node = GET_ATTRIBUT_RESULT.data.attribute;
                    }
                }
                let getAllGroups = this.props.attributeGroups;
                for (let group of this.state.selectedGroups) {
                    let getGroup = getAllGroups.find(e => e.node.id === group.node.id);
                    for (let attribute of getGroup.node.attributes.edges) {
                        if (attribute.node.id === UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id) {
                            attribute.node = GET_ATTRIBUT_RESULT.data.attribute;
                        }
                    }
                }
                localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(getAllGroups));
                localStorage.setItem('ATTRIBUTES', JSON.stringify(this.props.attributes));
                this.props.setAttributes(this.props.attributes);
                this.props.setAttributeGroups(getAllGroups);

                resolve();
            } catch (e) {
                console.log('error', e)
                this.handleError(e);
                reject();
            }
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }

        this.setState({
            openDialog: false
        });
    };

    handleSuccess = async () => {
        await this.updateRedux();
        await this.prepareTree();

        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'editAttribut'
            ? 'Attribut modifié'
            : this.state.editForm === 'addAttribut'
                ? 'Attribut ajouté'
                : this.state.editForm === 'editGroup'
                    ? 'Groupe modifié'
                    : 'Groupe ajouté');

        this.handleToggleDrawer();
        this.resetState();

        this.resetStaticFiles();

        this.props.stopLoading();
    };


    resetStaticFiles = () => {
        //--- reset static json
        const token = localStorage.getItem('AUTH_TOKEN');
        let headers = {'Content-Type': 'multipart/form-data'};
        if (token) headers['Authorization'] = `Bearer ${token}`;

        axios.delete(`${process.env.REACT_APP_API_ROOT}/api/media/GET_ATTR_GROUPE_WITH_ATTRIBUTES.json`,{headers}).then((result) => {
            this.props.client.query({ query: GET_ATTR_GROUPE_WITH_ATTRIBUTES, fetchPolicy: 'no-cache' }).then((result) => {
                //--- push result in file
                var bodyFormData = new FormData();
                bodyFormData.append('name', 'GET_ATTR_GROUPE_WITH_ATTRIBUTES.json');
                bodyFormData.append('content', JSON.stringify(result.data));

                let headers = {'Content-Type': 'multipart/form-data'};
                if (token) headers['Authorization'] = `Bearer ${token}`;
            
                axios.post(`${process.env.REACT_APP_API_ROOT}/api/media`,bodyFormData,{headers});
            }).catch((er) => {});            
        }).catch((er) => {});

        axios.delete(`${process.env.REACT_APP_API_ROOT}/api/media/GET_ATTRIBUTE_TYPES-1.json`,{headers}).then((result) => {
            this.props.client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: "/api/eav-types/1" }, fetchPolicy: 'no-cache' }).then((result) => {
                //--- push result in file
                var bodyFormData = new FormData();
                bodyFormData.append('name', 'GET_ATTRIBUTE_TYPES-1.json');
                bodyFormData.append('content', JSON.stringify(result.data));

                let headers = {'Content-Type': 'multipart/form-data'};
                if (token) headers['Authorization'] = `Bearer ${token}`;
            
                axios.post(`${process.env.REACT_APP_API_ROOT}/api/media`,bodyFormData,{headers});

            }).catch((er) => {});
        }).catch((er) => {});
    }


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }

        this.props.startLoading();

        switch (this.state.editForm) {
            case 'addAttribut':
                this.saveAddAttr().then(this.handleSuccess);
                break;
            case 'editAttribut':
                this.saveEditAttr().then(this.handleSuccess);
                break;
            case 'addGroup':
                this.saveAddGroup().then(this.handleSuccess);
                break;
            case 'editGroup':
                this.saveEditGroup().then(this.handleSuccess);
                break;
            default: return false;
        }
    };

    deleteMutation = (popup = true) => {
        let query = null;
        let variables = null;

        this.props.startLoading();

        if (this.state.editForm === 'editGroup') {
            query = DELETE_ATTRIBUTE_GROUPE;
            variables = { id: this.state.currentGroup.node.id };
        } else {
            query = UPDATE_ATTRIBUTE_STATUS;
            variables = { id: this.state.currentAttribute.id, status: !this.state.status };
        }

        this.props.client.mutate({
            mutation: query,
            variables
        }).then(async() => {
            // DELETE ATTRIBUTE IN STORE AND STORAGE

            if (this.state.editForm !== 'editGroup') {
                await this.updateRedux();
                this.prepareTree();
            } else {
                this.removeNode(this.state.currentGroup.node.id);
            }

            this.handleToggleDrawer();
            if (popup) {
                this.handleToggleDialog();
            }

            this.resetStaticFiles();

            this.props.stopLoading();
            this.props.snack(
                ALERT_SUCCESS,
                this.state.editForm === 'editGroup' ?
                    this.props.t("attributes.products.groupDeleted")
                    : this.state.status ? this.props.t("attributes.products.contactAdmin") : this.props.t("attributes.products.attributeReactived")
            );
            this.resetState();



        }).catch(e => {
            this.handleError(e)
        });
    };
    // [END] MUTATIONS

    removeNode = (id) => {
        let tree = this.state.treeData;
        let tree2 = this.state.unassignedAttributes;

        tree = tree.filter(e => e.node.id !== id);
        tree2 = tree2.filter(e => e.node.id !== id);

        for (let data of tree)
            data.children = data.children.filter(e => e.node.id !== id);

        this.setState({
            treeData: this.copyArrayOfObjects(tree),
            unassignedAttributes: this.copyArrayOfObjects(tree2)
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            for (let attrGroup of this.state.treeData) {
                this.convertToNode(attrGroup, true);

                for (let attr of attrGroup.children)
                    this.convertToNode(attr, false, attrGroup, true);
            }

            for (let unassignedAttribute of this.state.unassignedAttributes)
                this.convertToNode(unassignedAttribute, true, null, true);

            this.forceUpdate();
        });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    resetState() {
        this.setState({
            attr_id: '',
            grp_id: '',
            libelle: '',
            parent: '',
            attributeType: '',
            isSystem: false,
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeIdentifier: null,
            groupIdentifier: null,
            errors: {},
            useInCard: false,
            readyForm: false
        });
    };

    editAttribut = async (nodeInfo) => {
        this.resetState();
        this.setState({
            openForm: true,
            editForm: 'editAttribut',
        });
        const GET_ATTRIBUT_RESULT = await this.props.client.mutate({
            mutation: GET_ATTRIBUTE,
            variables: {
                'id': nodeInfo.node.id
            }
        });

        let choiceType = this.props.attributeTypes.find(e => e.node.input === 'select');
        for (let locale of this.props.locales) {
            let values = {};

            for (let groupLocale of nodeInfo.locales) {
                if (groupLocale.code === locale.node.code) {
                    values['attributeName'] = groupLocale.value;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }

        let options = [];

        for (let option of GET_ATTRIBUT_RESULT.data.attribute.attributeOptions.edges) {
            let values = {};
            for (let locale of this.props.locales) {
                for (let optionLocale of option.node.translation.translationDatas.edges) {
                    if (optionLocale.node.locale.code === locale.node.code) {
                        values[optionLocale.node.locale.code] = optionLocale.node.value;
                    }
                }
            }

            options.push({
                id: option.node.id,
                new: false,
                code: option.node.identifier,
                values
            });
        }

        let entries = [];
        if (GET_ATTRIBUT_RESULT.data.attribute.attributeType.input !== "select") {
            for (let entry of GET_ATTRIBUT_RESULT.data.attribute.attributeDictionnaries.edges) {
                entries.push({
                    id: entry.node.id,
                    new: false,
                    code: [entry.node.input],
                    values: entry.node.output,
                    //checkbox: entry.node.input.includes('|%|') ? true : false
                });
            }
        } else {
            for (let entry of GET_ATTRIBUT_RESULT.data.attribute.attributeOptionDictionnaries.edges) {
                entries.push({
                    id: entry.node.id,
                    new: false,
                    code: [entry.node.input],
                    values: entry.node.attributeOption.id,
                    //checkbox: entry.node.input.includes('|%|') ? true : false
                });
            }
        }
        this.setState({
            currentAttribute: GET_ATTRIBUT_RESULT.data.attribute,
            choiceType,
            attributeType: GET_ATTRIBUT_RESULT.data.attribute.attributeType.id,
            status: GET_ATTRIBUT_RESULT.data.attribute.status,
            attributeIdentifier: GET_ATTRIBUT_RESULT.data.attribute.identifier,
            isSystem: GET_ATTRIBUT_RESULT.data.attribute.isSystem,
            isRequired: GET_ATTRIBUT_RESULT.data.attribute.isRequired,
            isSearchable: GET_ATTRIBUT_RESULT.data.attribute.isSearchable,
            useInCard: GET_ATTRIBUT_RESULT.data.attribute.useInCard || false,
            internalFilter: GET_ATTRIBUT_RESULT.data.attribute.internalFilter,
            selectedGroups: [].concat(GET_ATTRIBUT_RESULT.data.attribute.attributeGroup.edges),
            values: choiceType.node.id === GET_ATTRIBUT_RESULT.data.attribute.attributeType.id ? options : [],
            dictionnaryValues: entries,
            readyForm: true
        });
    };

    addAttribut = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({
            readyForm: true,
            openForm: true,
            editForm: 'addAttribut',
            selectedGroups: [],
            choiceType: this.props.attributeTypes.find(e => e.node.input === 'select'),
            attributeType: this.props.attributeTypes[0].node.id,
            values: [],
            dictionnaryValues: []
        });
    };

    addAttributByGrp = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({
            openForm: true,
            readyForm: true,
            editForm: 'addAttribut',
            selectedGroups: [this.state.treeData.find(e => e.node.id === nodeInfo.id)],
            attributeType: this.props.attributeTypes[0].node.id,
            choiceType: this.props.attributeTypes.find(e => e.node.input === 'select'),
            values: [],
            dictionnaryValues: []
        });
    };

    addGroupAttribut = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {},
            });
        }

        this.setState({
            readyForm: true,
            openForm: !this.state.openForm,
            editForm: 'addGroup',
        });
    };

    editGroupAttribut = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            let values = {};

            for (let groupLocale of nodeInfo.locales) {
                if (groupLocale.code === locale.node.code) {
                    values['groupName'] = groupLocale.value;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }

        this.setState({
            readyForm: true,
            openForm: true,
            editForm: 'editGroup',
            currentGroup: nodeInfo,
            groupIdentifier: nodeInfo.node.identifier.indexOf('_') > -1
                ? nodeInfo.node.identifier.split('_')[1]
                : nodeInfo.node.identifier
        });
    };

    doneTyping = (stateName) => {
        if (stateName === 'attributeName') {
            this.setState({
                attributeIdentifier: slugify(this.state[this.state.currentLang].attributeName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            })
        }
        else if (stateName === 'groupName') {
            this.setState({
                groupIdentifier: slugify(this.state[this.state.currentLang].groupName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            })
        }

        if (this.state.attributeIdentifier) {
            this.typeTesting = 'attribute';
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.attributeIdentifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'attributeIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        if (this.state.groupIdentifier) {
            this.typeTesting = 'attribute_group';
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.groupIdentifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'groupIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values
            });
        } else {
            this.setState({
                [stateName]: value
            });
        }
        if ((this.state.editForm === "addAttribut" || this.state.editForm === "addGroup") && (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier'))
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        if (stateName === "attributeType"){
            if (this.state.choiceType.node.input === "select"){
                this.handleFormError("dictionnaryValues", false)
            }else{
                this.handleFormError("dictionnaryValues", true)
            }
        }
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    expand = (expanded) => {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }),
        });
    };

    async initAttributs() {
        checkRouting(this.props)
        await this.handleInitData(this.props.typeAttributs)
        // this.updateRedux();
        this.prepareTree();
    };

    handleInitData = (typeAttributs) => {
        let content;
        let showTab = false;
        return new Promise(async (resolve, reject) => {
            switch (typeAttributs) {
                case 'product':
                    content = {
                        title: this.props.t("catalogs.catalogs.manageAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-attribut",
                        textAdd: this.props.t("attributes.products.addAttributeGroup"),
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
                    break;
                case 'category':
                    content = {
                        title: this.props.t("attributes.products.manageCatAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-categorie",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
                    break;
                case 'point':
                    content = {
                        title: this.props.t("attributes.products.manageStoreAttributes"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-retailer",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
                    break;
                default:
                    content = {
                        title: this.props.t("attributes.products.manageAttributesDynamic"),
                        subtitle: this.props.t("attributes.products.manageAttributesInfo"),
                        picto: "picto-attribut",
                        textAdd: null,
                        textImport: this.props.t("attributes.products.addAttribute"),
                    };
            }
            this.setState({
                showTab: showTab,
                content: content,
            })
            resolve()
        })
    };

    modifyTreeView = (treeData) => {
        this.setState({ treeData });
    };

    updatePositionAttribute = async (treeData, attribute) => {
        return new Promise(async (resolve, reject) => {
            let arrayNewPositions = [];
            let getGroup = treeData.find(e => e.node.id === attribute.parent);
            if (getGroup){
                arrayNewPositions = getGroup.children.map(e => parseInt(e.node.id.replace('/api/attributes/', '')))
            }
            this.setState({
                selectedGroups: [getGroup]
            })

            resolve(arrayNewPositions);
        });
    };

    handlerUpdatePositionAttribute = async (treeData, attribute) => {
        this.props.startLoading();
        let getNewArray = await this.updatePositionAttribute(treeData, attribute);
        request(`${process.env.REACT_APP_API}/attributes/order`, 'post', getNewArray).then(
            async (data) => {
                if (data.success) {
                    await this.updateRedux();
                    this.props.snack(ALERT_SUCCESS, "Attribut déplacé avec succès");
                    this.props.stopLoading();
                }else{
                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));
                    this.props.stopLoading();
                }
            }
        ).catch(async(e) => {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));
            this.props.stopLoading();
        });
    };

    componentDidMount() {
        checkRouting(this.props);
        this.initAttributs();
    };

    componentDidUpdate(prevProps) {
        if (this.props.typeAttributs !== prevProps.typeAttributs) {
            this.setState({
                openForm: false,
            })
            this.initAttributs();
        }
    }

    render() {
        const { treeData, unassignedAttributes } = this.state;
        return (
            <div>
                {this.state.content ? (
                    <TopPanel
                        icomoon={this.state.content.picto}
                        colorIcomoon={colors.blue.darker.hue300}
                        title={this.props.t(this.state.content.title)}
                        subtitle={this.props.t(this.state.content.subtitle)}
                        handlerAdd={this.addGroupAttribut}
                        textAdd={this.state.content.textAdd}
                        handlerImport={this.addAttribut}
                        textImport={this.props.t(this.state.content.textImport)}
                        searchHandler={false}
                        gradientColor1={colors.menu.regular}
                        gradientColor2={colors.menu.darker}
                        openForm={this.state.openForm}
                        buttonAvailable={treeData ? true : false}
                        windowWidth={this.props.windowWidth}
                        hasBorder={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        locales={this.props.locales}
                    />
                ) : ''
                }
                <Grid container direction="column" justifyContent="center" spacing={0} style={{ width: '100%' }}>
                    <Grid container direction="row" spacing={4}>
                        {treeData ?
                            <>
                                {
                                    !this.props.noGroup ?
                                        <Grid item lg={6} md={6} sm={this.props.windowWidth > 1200 ? 6 : 12} xs={12}>
                                            <h3>{this.props.t("attributes.products.attributeGroups")}</h3>
                                            <TreeView
                                                typeOfTree={'attribut'}
                                                dataTree={treeData}
                                                editCat={this.editGroupAttribut}
                                                editItem={this.editAttribut}
                                                addSubcategory={this.addAttributByGrp}
                                                expand={this.expand}
                                                onChange={(treeData) => this.modifyTreeView(treeData)}
                                                onMoveNode={this.handlerUpdatePositionAttribute}
                                                canAdd={true}
                                                canModify={true}
                                                loading={this.state.loadingTree}
                                            />
                                        </Grid>
                                        : null
                                }
                                <Grid item lg={this.props.noGroup ? 12 : 6} md={this.props.noGroup ? 12 : 6} sm={this.props.noGroup ? 12 : this.props.windowWidth > 1200 ? 6 : 12} xs={12}>
                                    <h3>{this.props.noGroup ? this.props.t("attributes.products.attributesDot") : this.props.t("attributes.products.unassignedAttributesDot")} </h3>
                                    <TreeView
                                        noExpand={true}
                                        typeOfTree={'attribut'}
                                        dataTree={unassignedAttributes}
                                        editCat={this.editGroupAttribut}
                                        editItem={this.editAttribut}
                                        addSubcategory={this.addAttributByGrp}
                                        onChange={unassignedAttributes => this.setState({ unassignedAttributes })}
                                        none={<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={this.props.t("common.add")} onClick={this.addAttribut} />}
                                        canModify={true}
                                        canAdd={true}
                                        loading={this.state.loadingTree}
                                    />
                                </Grid>
                            </>
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>

                {this.state.openForm ?
                    <LayoutBuilder
                        isSublayout={false}
                        readyForm={this.state.readyForm}
                        opened={this.state.openForm}
                        forClose={this.handleToggleDrawer}
                        handlerMutation={() => (this.state.status ? this.handlerMutation() : this.deleteMutation(false))}
                        dataLayout={this.state.readyForm
                            ? this.state.editForm === 'editAttribut'
                                ? formAttributEdit(
                                    this.copyArrayOfObjects(this.state.treeData),
                                    this.state.attributeType,
                                    this.state.currentLang,
                                    this.props.attributeTypes,
                                    this.props.locales,
                                    this.state.selectedGroups,
                                    this.state.isSystem,
                                    !this.state.status,
                                    this.props.noGroup
                                )
                                : this.state.editForm === 'addAttribut'
                                    ? formAttributAdd(
                                        this.copyArrayOfObjects(this.state.treeData),
                                        this.state.attributeType,
                                        this.state.currentLang,
                                        this.props.attributeTypes,
                                        this.props.locales,
                                        this.state.selectedGroups,
                                        this.props.noGroup,
                                    )
                                    : this.state.editForm === 'addGroup'
                                        ? formGroupAttributAdd()
                                        : formGroupAttributEdit()
                            : null
                        }
                        allState={this.state}
                        icomoon={(this.state.editForm === 'editGroup' || this.state.editForm === 'editAttribut') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        checkError={() => { }}
                        deleteMutation={
                            !this.state.isSystem && this.state.status ?
                                (this.state.editForm === 'editGroup' || this.state.editForm === 'editAttribut') ?
                                    this.handleToggleDialog
                                    : null
                                : null
                        }
                        deleteText={this.state.editForm === 'editGroup' ? this.props.t("attributes.products.deleteGroup") : this.state.editForm === 'editAttribut' && this.state.status ? this.props.t("attributes.products.disabledAttribute") : null}
                        deleteButton={this.state.editForm}
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                    />
                    : null
                }

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            this.state.editForm === 'editGroup'
                                ? this.props.t("attributes.products.sureDeleteGroup")
                                : this.props.t("attributes.products.sureTurnOffAttribute")
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                this.state.editForm === 'editGroup'
                                    ? this.props.t("attributes.products.verySureDeleteGroup")
                                    : this.props.t("attributes.products.verySureDeactiveAttribute")
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            {this.props.t("spread.active_assets.cancel")}
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            {this.props.t("spread.active_assets.deactivate")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    updateRedux = async () => {
        let typeToFetch = this.props.typeAttributs;
        let attributes = this.props.attributes;
        let data = null;
        for (let type of attributes.eavTypes) {
            if (type.node.code === typeToFetch) {
                let typeData = await this.props.client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: type.node.id }, fetchPolicy: 'no-cache' });
                data = typeData.data.eavType;
            }
        }
        attributes[this.props.typeAttributs] = data
        let getAllGroups = this.props.attributeGroups;
        if (this.state.selectedGroups && this.state.selectedGroups.length > 0) {
            for (let group of this.state.selectedGroups) {
                let getGroup = getAllGroups.find(e => e.node.id === group.node.id);
                if (getGroup) {
                    getGroup.node.attributes = attributes[this.props.typeAttributs].attributes
                }
            }
        }

        this.props.setAttributes(attributes);
        this.props.setAttributeGroups(getAllGroups);
        localStorage.setItem('ATTRIBUTES', JSON.stringify(attributes));
        localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(getAllGroups));
    }

    prepareTree = () => {
        return new Promise(async (resolve, reject) => {
            this.setState({ loadingTree: true })
            let groups = [];
            let unassignedAttributes = [];
            if (this.props.noGroup) {
                if (this.props.butInGroup) {
                    unassignedAttributes = this.props?.attributes[this.props.typeAttributs]?.attributes.edges;
                } else {
                    unassignedAttributes = this.props?.attributes[this.props.typeAttributs]?.attributes.edges.filter(e => e.node.attributeGroup?.edges.length === 0 ?? false);
                }
            } else {
                let attrGroupResult = this.props.attributeGroups;
                for (let attrGroup of attrGroupResult) {
                    if ((!attrGroup.node.isSystem && !attrGroup.node.isForContent) || attrGroup.node.identifier === 'default') {
                        attrGroup.children = this.copyArrayOfObjects(this.props.attributes[this.props.typeAttributs].attributes.edges.filter(e => {
                            return e.node.attributeGroup.edges.find(e => e.node.id === attrGroup.node.id);
                        }));

                        this.convertToNode(attrGroup, true);

                        for (let attr of attrGroup.children)
                            this.convertToNode(attr, false, attrGroup, true);
                        groups.push(attrGroup);
                    }
                }
                unassignedAttributes = this.props.attributes[this.props.typeAttributs].attributes.edges
                    .filter(e => !e.node.isSystem && (e.node.attributeGroup?.edges.length === 0 ?? false));
            }

            for (let unassignedAttribute of unassignedAttributes)
                this.convertToNode(unassignedAttribute, true, null, true);
            // for (let group of groups) {
            //     group.children = group.children.sort(function (a, b) {
            //         return a.node.position - b.node.position;
            //     });
            // }

            this.setState({
                treeData: toggleExpandedForAll({
                    treeData: this.copyArrayOfObjects(groups),
                    expanded: false,
                }),
                unassignedAttributes: this.copyArrayOfObjects(unassignedAttributes)
            });

            if (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen) {
                this.addAttribut()
            }
            this.setState({ loadingTree: false })
            resolve();
        });
    }

    getIdentifier = identifier => identifier.indexOf('_') > -1
        ? identifier.split('_')[1]
        : identifier;

    convertToNode(data, root, parent, isAttribute) {
        let getTraduction = data.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === this.state.currentLang
        );

        if (root && data.node.identifier === 'default')
            data.noAction = true;

        data.locales = [];

        for (let { node } of data.node.translation.translationDatas.edges) {
            data.locales.push({
                value: node.value,
                id: node.locale.id,
                code: node.locale.code,
                translationDataId: node.id
            });
        }

        if (!root) {
            data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? this.getIdentifier(data.node.identifier);
            data.parent = parent.node.id;
            data.isDirectory = false;
            data.expanded = true;
            data.isItem = true;
            data.canDrag = false;
        } else {
            data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? this.getIdentifier(data.node.identifier);
            data.isDirectory = true;
            data.expanded = true;
            data.isItem = isAttribute;
            data.canDrag = false;
        }
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => this.props.history.push(route);
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        attributeTypes: state.attributeTypes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
        setAttributeGroups: (attributeGroups) => dispatch({ type: SET_ATTRIBUTE_GROUPS, payload: { attributeGroups } }),
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AttributesProducts))));