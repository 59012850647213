import { useEffect, useState } from "react"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import colors from "../../../../config/theme/colors"
import { ROUTE_CONTENU_ADD, ROUTE_CONTENU_LIST } from "../../../../js/constants/route-names"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, TextField } from "@material-ui/core"
import TraductionSelect from "../../../layouts/TopPanel/TraductionSelect"
import { eventService } from "../../../../js/services/event.service"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import { withTranslation } from "react-i18next"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router"
import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import request from '../../../../js/utils/fetch';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader"
import styled from "styled-components"
import OurTooltip from "../../../ui/tooltip/Tooltip"
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import { DELETE_CONTENT, SEARCH_CONTENTS_SKU_BY_CAT } from '../../../../queries/contents';
import Listing from "../../../layouts/Listing/Listing"
import { listMappers, listSettings, perPageOptions } from "./config/listContents.config"
import EmptyCard from "../../../ui/empty-card/EmptyCard"
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsConfig from './config/importProducts.config';
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom"
import BreadcrumbCustom from "../../../ui/breadcrumb/Breadcrumb"

const FolderContainer = styled(Grid)`
    display: grid;
    grid-template-columns:
        ${props => props.windowWidth > 1500 ? 'repeat(auto-fill, minmax(20%, 1fr))' // 4 columns
        : props.windowWidth > 765 ? 'repeat(auto-fill, minmax(25%, 1fr))' // 3 columns
            : 'repeat(auto-fill, minmax(33%, 1fr))'}; // 2 columns
    width: 100%;
    gap: 17px;
    margin-top: 9px;
`

const FolderCustom = styled(Grid)`
    background-color: ${colors.white};
    border: 0.5px solid ${colors.grey.lighter.hue700};
    padding: 10px 15px;
    color: ${colors.black.regular};
    font-weight: bold;
    height: 52px;
    gap: 10px;
    cursor: pointer;
    '&:hover': {
        background-color: ${colors.grey.lighter.hue900};
    }
`

const PageWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    position: relative;
    min-height: calc(100% - 64px);

    & > .layout-wrapper {
        display: none;
    }
`;


const ListContents =props=>{
    const [categories,setCategories]=useState([])
    const [categorieIsLoaded,setCategoriesLoaded]=useState(false)

    const [skuList,setSkuList]=useState([])
    const [skuListIsLoaded,setSkuListIsLoaded]=useState(false)
    const [currentFolder,setCurrentFolder]=useState(null)
    const [previousFolders,setPreviousFolders]=useState([])
    const [openForm,setOpenForm]=useState(false)
    const [error,setError]=useState(false)
    const [currentLang,setCurrentLang]=useState(props.locales[0].node.code)
    const [state,setState]=useState({
        importFile:null
    })
    const [filters,setFilters]=useState({
        name:null
    })

    useEffect(()=>{                        
        const savedCategory =JSON.parse(localStorage.getItem('CONTENT_CATEGORY'))
        if (savedCategory) {
            setCurrentFolder(savedCategory.currentFolder)
            setPreviousFolders(savedCategory.previousFolders)
        }
        props.history.listen((location, action) => {                        
            if (!location.pathname.includes('/contenu/list',0)) {
                localStorage.removeItem('CONTENT_CATEGORY')
            }                        
        });
    },[])

    const goTo = (route, state) => {
        props.history.push({
            pathname: route,
            state: {
                contentId: state?.id,
                name: state?.name
            }
        });
    };

    const handleChange=(e, value) =>{
        clearTimeout(this.typingSearchTimer);
        if (e?.target?.value) {
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        }
        else if (value) {
            this.setState({
                searchValue: e.target.value
            }, this.handleSearch);
        }
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: ''
            }, () => {
                if (this.state.activeCategorie.length <= 1) {
                    this.handleGetContents();
                }
                else {
                    this.handleChangeTab(null, null, this.state.idActiveCat);
                }
            });
        }
    }

    const handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    const handleGetCategories=()=> {
        setCategoriesLoaded(false)
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/content`, 'get').then(
                (data) => {
                    if (data.success && data.datas.length > 0) {                        
                        setCategories(data.datas)
                        setCategoriesLoaded(true)
                    }else{
                        setError(true)
                    }
                    resolve();
                }
            );
        });
    }


    const getSkuList=() =>{
        setSkuListIsLoaded(false)                        
        props.client.query({
                query: SEARCH_CONTENTS_SKU_BY_CAT,                    
                variables:{
                    "idlist": currentFolder.id,                         
                },
                fetchPolicy: 'no-cache'
            }).then(result => {   
                let list =[]
                if (result?.data?.contentCategories[0]?.contents?.edges?.length === 0) {
                    list = []
                }else{
                    result?.data?.contentCategories[0]?.contents?.edges?.map((item)=>{                                            
                        list.push(item.node.sku)
                    })
                }           
                setSkuList(list)      
                setSkuListIsLoaded(true)                                            
            })        
    }

    const handlerMutation = async () => {
        props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'content').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep
        };

        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, this.props.t("content.content.importMediaSuccess"), 6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch (e) {
            this.props.snack(ALERT_ERROR, props.t("content.content.importMediaFailed"));
            this.props.stopLoading();
        }
    };


    const resetContents =()=>{
        setCurrentFolder(null)
    }

    useEffect(()=>{        
        if (!currentFolder) {
            setSkuList([])
            handleGetCategories()
        }else{
            getSkuList()
        }        
    },[currentFolder])

    useEffect(()=>{                
        saveCategoryOnLocalStorage()
    },[currentFolder])    

    const saveCategoryOnLocalStorage = () => {        
        if (currentFolder) {
            const objectToSave = {
                currentFolder,
                previousFolders
            }
            localStorage.setItem('CONTENT_CATEGORY',JSON.stringify(objectToSave));    
        }else{
            localStorage.removeItem('CONTENT_CATEGORY');            
        }
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {
        setState({
            ...state,
            [stateName]: value?.target?.value ?? value
        }, callback);
    };
    
    // Click on breadcrumb
    const getClickedCategory = (id) => {        
        if (id) {
            const categoryIndex = previousFolders.findIndex(folder => folder?.id === id)            
            setCurrentFolder(previousFolders[categoryIndex])
            setPreviousFolders(previousFolders.slice(0, categoryIndex))
        } else {            
            setCurrentFolder(null)
            setPreviousFolders([])
        }
    }

    // Click on Folder
    const getPreviousFolders = (category) => {
        if(previousFolders.length <= 1){
            const parentCategory = categories.find(folder => folder.id === category.parent)
            if (parentCategory) {
                previousFolders.push(parentCategory)
            }else{
                previousFolders.push(null)
            }
        } else {
            previousFolders.push(currentFolder)
        }
    }

    const deleteMutation = (id) => {
        let query = null;
        let variables = null;

        props.startLoading()

        query = DELETE_CONTENT;
        variables = { id: id };

        request(`${process.env.REACT_APP_API}/wordpress/content/delete/${id.replace('/api/contents/', '')}`, 'get');

        props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            getSkuList()
            props.snack(ALERT_SUCCESS, "Le contenu a bien été supprimé", 6000);
            props.stopLoading()            
        })
    };

    const handleDuplicate = async (contentId) => {
        try {
            const authToken = localStorage.getItem("AUTH_TOKEN");
            const response = await fetch(`${process.env.REACT_APP_API}/content/clone/${contentId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
              }
            });            
          if (response.ok) {
            const clonedContent = await response.json();
            if (clonedContent.success) {
                getSkuList();
                props.snack(ALERT_SUCCESS, "Le contenu a bien été dupliqué", 3000);
                props.stopLoading();
            } else {
                props.snack(ALERT_ERROR, "Une erreur est survenue lors de la duplication", 3000);
            }
          } else {
            console.error('Failed to clone content. Status:', response.status);
          }
        } catch (error) {
          console.error('Error cloning content:', error);
        }
    };


    return (
        <PageWrapper>
            <TopPanel
                icomoon="picto-content"
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("content.content.manageContents")}
                subtitle={props.t("content.content.manageContentSubtitle")}
                handlerAdd={() => goTo(ROUTE_CONTENU_ADD)}
                textAdd={props.t("content.content.addContent")}
                handlerImport={() => setOpenForm(!openForm)}
                textImport={props.t("content.content.importContent")}
                searchHandler={handleChange}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                openForm={openForm}
                buttonAvailable={categorieIsLoaded}
                hasBorder={true}
            />
            <Grid container alignItems="center" style={{
                display: 'grid',
                gridTemplateRows: 'auto auto auto 1fr'
            }}>                              
                {/* <Grid item xs={12}>
                    <TraductionSelect
                        currentLang={currentLang}
                        handleLang={handleLang}
                        locales={props.locales}
                    />
                </Grid>     */}

                {/* Filtres */}
                {/* <Grid item container xs={12}>
                    {
                        categories.length > 0 ?
                            <AccordionCustom defaultExpanded={true} title={props.t('products.list.filter.filterSearch')}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <TextField
                                            placeholder="Recherche"
                                            variant="outlined"
                                            fullWidth  
                                            onChange={(e)=>{setFilters({...filters,name:e.target.value.length >0?e.target.value:null})}}
                                        />                                            
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                            : null
                    }
                </Grid>        */}
                 {/* Breadcrumb */}
                <Grid item xs={12} style={{marginBottom:18, marginTop: 18}}>                                                        
                    {
                        categories.length > 0 ?
                            <BreadcrumbCustom 
                                currentFolder={currentFolder}
                                previousFolders={previousFolders}
                                rootName={'Tous les contenus'}
                                windowWidth={props.windowWidth}
                                getClickedCategory={getClickedCategory}
                                nbPreviousItems={4}
                                separator={<ArrowForwardIosSharpIcon fontSize='small' />}
                                size={25}
                            ></BreadcrumbCustom>
                        :null
                    }
                </Grid>

                {/* Folders */}
                <Box>
                    <Grid item xs={12}>
                        <Typography style={{ color: colors.black.regular,fontSize: props.windowWidth < 1200 ? '20px' : `20px` }}>
                            {
                                categories.length > 0 ?
                                    currentFolder ?                        
                                        "Liste des contenus du template"
                                        :"Liste des templates"
                                :null
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            categorieIsLoaded ?
                                <FolderContainer container windowWidth={props.windowWidth}>                                        
                                    {
                                        (!currentFolder 
                                            // Root
                                            ? (categories && categories.length > 0) ? categories : []
                                            // Subfolders
                                            : currentFolder.childrens
                                        )?.map((category, i) => {         
                                            const title =  category?.categoryDatas?.attribute 
                                                                ? category?.categoryDatas?.find(data => data?.attribute === "/api/attributes/11")?.value 
                                                                : category.libelle;                                                                                                  
                                                return (                                                    
                                                    <FolderCustom
                                                        style={{
                                                            display: 'flex',
                                                            height: '80px',
                                                            padding: '0px 25px'
                                                        }}
                                                        key={`cat-${category.id}`}
                                                        alignItems={'center'}
                                                        onClick={() => {
                                                            getPreviousFolders(category)
                                                            setCurrentFolder(category)
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <FolderSharpIcon style={{ marginTop: '3px', fontSize: '28px' }} />
                                                        </Grid>
                                                        <Grid item
                                                            style={{
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                fontSize: '20px'
                                                            }}
                                                        >
                                                            {title} {category?.contentsTotal !== 0 ? `(${category?.contentsTotal})`:null }
                                                        </Grid>
                                                    </FolderCustom>
                                                );
                                        })
                                    }
                                </FolderContainer>                                        
                            : null
                        }
                    </Grid>
                </Box>

                {/* Listing */}
                <Grid item container xs={12} spacing={2} style={{paddingTop:20, height: '100%', position: 'relative'}}>
                    {
                        currentFolder ?                            
                            skuListIsLoaded?
                                skuList.length >0?
                                    <Listing
                                        label = 'contents'
                                        settings = {listSettings}
                                        perPageOptions = {perPageOptions}
                                        mappers = {listMappers}                                
                                        pagination={true}
                                        identifier = 'contents'
                                        propsToPass={{
                                            actionButtonDelete: deleteMutation,
                                            actionButtonSecond: (id) => handleDuplicate(id),
                                        }}
                                        queryVariables={{
                                            "nbperpage": 12, 
                                            "skulist": skuList,                                    
                                        }}                                        
                                        viewsOptions = {{
                                            current : 'card',
                                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                        }}
                                    />                            
                                :currentFolder?.childrens.length === 0 ?
                                    (<Typography style={{
                                        margin : "0"
                                    }}>
                                        Aucun résultats
                                    </Typography>)
                                : null
                            : null
                        :null
                    }
                </Grid>   
                {error ?
                    (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={ "Ajouter un contenu"} onClick={() => goTo(ROUTE_CONTENU_ADD)} picto={EmptyProduct}  mtImg={2} />)
                :null}             
            </Grid>
            <LayoutBuilder
                isSublayout={true}
                opened={openForm}
                icomoon={'ico-import-fichier'}
                forClose={() => setOpenForm(false)}
                dataLayout={importProductsConfig}
                // drawerWidth={props.drawerWidth}
                // handleCancel={handleCancel}
                handlerMutation={handlerMutation}
                allState={state}
                stateCallback={stateCallback}
                // stepperButtonDisabled={[() => state.headers === null, null]}
                stepperButtonAction={[null, null]}
                backStepperButtonAction={[null, null, null]}                    
            />
        </PageWrapper>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListContents))));